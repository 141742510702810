<template>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button color="medium" @click="clear">Clear</ion-button>
        </ion-buttons>
        <ion-buttons slot="end">
          <ion-button color="medium" @click="cancel">Cancel</ion-button>
        </ion-buttons>
      </ion-toolbar>
      <ion-item>
        <ion-input class="inputNm" type="text" placeholder="착지를 입력하세요." @input="searchGroup($event)"></ion-input>
      </ion-item>
    </ion-header>
    <ion-content class="my-modal-class4">
      <ion-list>
        <ion-item class="group-list" v-for="detail in dplacelist" :key="detail">
          <ion-label ref="input" class="dplacename" @click="sendSaleNm(detail.dplaceName)"> {{ detail.dplaceName }}</ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </template>
  
  <script>
  import {
    IonContent,
    IonToolbar,
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
    modalController,
    IonList,
    IonHeader,
    IonButtons,
  } from "@ionic/vue";
  import { reactive, defineComponent } from 'vue';
  import { saleinfos } from '@/api/services/assignnew';
  
  export default defineComponent({
    name: "DplaceListModal",
    components: {
      IonButton,
      IonContent,
      IonInput,
      IonList,
      IonItem,
      IonLabel,
      IonToolbar,
      IonHeader,
      IonButtons
    },
    methods: {
      cancel() {
        return modalController.dismiss(null, 'cancel');
      },
      sendSaleNm(dplacenm) {
        // console.log(salenm);
        return modalController.dismiss(dplacenm, 'confirm');
      },
      clear() {
        // console.log(salenm);
        return modalController.dismiss(null, 'clear');
      },
  
    },
    setup() {
      
      const { fetchDplaceDatas, dplacelist } = saleinfos();
      
      fetchDplaceDatas();
      
  
  
      const detail = reactive({
        dplaceName: ''
      });
  
  
      async function searchGroup(event) {
        //console.log(this.salelist[0].saleName);
  
        const len = this.dplacelist.length;
  
        for (let i = 0; i < len; i++) {
          if (
            this.dplacelist[i].dplaceName.includes(event.target.value) === false
          ) {
            document.querySelectorAll('.dplacename')[i].style.display = "none";
            document.querySelectorAll('.group-list')[i].style.display = "none";
          } else {
            document.querySelectorAll('.dplacename')[i].style.display = "flex";
            document.querySelectorAll('.group-list')[i].style.display = "flex";
          }
        }
  
      }
  
      return { fetchDplaceDatas, dplacelist, detail, searchGroup}
    },
  
  });
  </script>
  
  <style scoped>
  .search-input {
    display: block;
    padding: 4px 8px;
    margin: 10px auto;
    width: 300px;
    font-size: 16px;
    outline: none;
  }
  
  .group-list {
    margin: auto;
  
    display: flex;
  }
  
  .group-list ion-label {
    display: flex;
    justify-content: left;
    align-items: left;
    height: 32px;
    list-style-type: none;
  }
  </style>
  