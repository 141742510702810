<template>
    <ion-page>
        <app-header back="home" title="출하배정[인천]" />
        <ion-content :fullscreen="true">
            <template v-if="isConnected">
                <div id="container" class="text-align-center">
                    <ion-grid style="text-align: left">
                        <ion-row class="ion-justify-content-left">
                            <ion-col size-xl="1.5" size-md="1.5" size-xs="3"
                                style="margin-top: auto; margin-bottom: auto;">
                                <span>출하일자</span>
                            </ion-col>
                            <ion-col size-xl="2" size-md="2" size-xs="5">
                                <ion-input class="calendar" v-model="frDate" id="frdate" readonly
                                    @ionChange="changeDate($event)" @click="popChange"><ion-icon
                                        :icon="calendar"></ion-icon></ion-input>
                                <ion-modal trigger="frdate" id="www" @willDismiss="onWillDismiss">
                                    <ion-datetime class="dateitem" id="datetime" display-format="YYYY-MM"
                                        v-model="frDate" presentation="month-year"
                                        :show-default-buttons="true"></ion-datetime>
                                </ion-modal>
                            </ion-col>
                            <ion-col size-xl="3" size-md="3" size-xs="4" style="text-align: center">
                                <ion-select interface="popover" @ionChange="selectSicd($event)" :value="sicd.code">
                                    <ion-select-option value="4000">인천공장</ion-select-option>
                                </ion-select>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                    <ion-list>
                        <ion-item>
                            <ion-label>착지</ion-label>
                            <ion-input class="dplace" style="text-align: right" placeholder="착지검색" readonly
                                id="open-modal1" @ionInput="cgname($event)" @click="modalChange"></ion-input>
                            <ion-modal ref="modal1" trigger="open-modal1" @willDismiss="onWillDismiss">
                                <ion-header>
                                    <ion-toolbar>
                                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                        <ion-buttons slot="start">
                                            <ion-button color="medium" @click="clear1()">Clear</ion-button>
                                        </ion-buttons>
                                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                        <ion-buttons slot="end">
                                            <ion-button color="medium" @click="cancel1()">Cancel</ion-button>
                                        </ion-buttons>
                                    </ion-toolbar>
                                    <ion-item>
                                        <ion-input style="margin-left: 10px" class="inputNm" type="text"
                                            placeholder="착지를 입력하세요." @input="searchGroup1($event)"></ion-input>
                                    </ion-item>
                                </ion-header>
                                <ion-content class="my-modal-class4">
                                    <ion-list>
                                        <ion-item class="group-list" v-for="detail in dplacelist" :key="detail">
                                            <ion-label ref="input" class="dplacename"
                                                @click="sendDplNm(detail.dplaceName)"> {{
                                                    detail.dplaceName
                                                }}</ion-label>
                                        </ion-item>
                                    </ion-list>
                                </ion-content>
                            </ion-modal>
                        </ion-item>
                        <ion-item>
                            <ion-label>영업소</ion-label>
                            <ion-label class="info">{{ saleinfo.officename }}</ion-label>
                        </ion-item>
                        <ion-item>
                            <span>청구처</span>
                            <ion-label class="info">{{ saleinfo.salename }}</ion-label>
                        </ion-item>
                        <ion-item>
                            <ion-label>사업자번호</ion-label>
                            <ion-label class="info">{{ saleinfo.salesano }}</ion-label>
                        </ion-item>
                        <ion-item>
                            <ion-label>대표자</ion-label>
                            <ion-label class="info">{{ saleinfo.saleower }}</ion-label>
                        </ion-item>
                        <ion-item>
                            <ion-label>주문유형</ion-label>
                            <ion-select placeholder="선택" interface="popover" :value="saleinfo.ordercode"
                                @ionChange="changeOrder($event)" style="font-size: 13px;">
                                <ion-select-option v-for="item in orderlist" :key="item.orderName"
                                    :value="item.orderCode">
                                    {{ item.orderName }}</ion-select-option>
                            </ion-select>
                        </ion-item>
                        <ion-item>
                            <ion-label>인도조건</ion-label>
                            <ion-select placeholder="선택" interface="popover" :value="saleinfo.delicode"
                                @ionChange="changeDeli($event)" style="font-size: 13px;">
                                <ion-select-option v-for="item in deliverylist" :key="item.deliveryName"
                                    :value="item.deliveryCode"> {{
                                        item.deliveryName
                                    }} </ion-select-option>
                            </ion-select>
                        </ion-item>
                        <ion-item>
                            <ion-label>품목</ion-label>
                            <ion-select placeholder="선택" interface="popover" :value="saleinfo.itnmcode"
                                @ionChange="changeItnm($event)" style="font-size: 13px;">
                                <ion-select-option v-for="item in itnmlist" :key="item.itnmName" :value="item.itnmCode">
                                    {{ item.itnmName }}
                                </ion-select-option>
                            </ion-select>
                        </ion-item>
                        <ion-item>
                            <ion-label>운송사</ion-label>
                            <ion-input class="sale" style="text-align: right; font-size: 13px;" placeholder="운송사검색"
                                readonly id="open-modal" @ionInput="saname($event)" @click="modalChange"></ion-input>
                            <ion-modal ref="modal" trigger="open-modal" @willDismiss="onWillDismiss">
                                <ion-header>
                                    <ion-toolbar>
                                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                        <ion-buttons slot="start">
                                            <ion-button color="medium" @click="clear()">Clear</ion-button>
                                        </ion-buttons>
                                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                        <ion-buttons slot="end">
                                            <ion-button color="medium" @click="cancel()">Cancel</ion-button>
                                        </ion-buttons>
                                    </ion-toolbar>
                                    <ion-item>
                                        <ion-input style="margin-left: 10px" class="inputNm" type="text"
                                            placeholder="운송사를 입력하세요." @input="searchGroup($event)"></ion-input>
                                    </ion-item>
                                </ion-header>
                                <ion-content class="my-modal-class4">
                                    <ion-list>
                                        <ion-item class="group-list" v-for="detail in salelist" :key="detail">
                                            <ion-label ref="input" class="salename"
                                                @click="sendSaleNm(detail.saleName)"> {{ detail.saleName }}</ion-label>
                                        </ion-item>
                                    </ion-list>
                                </ion-content>
                            </ion-modal>
                        </ion-item>
                        <ion-item>
                            <ion-label>배정가능수량</ion-label>
                            <ion-label class="info">{{
                                quntinfo.qunt.toLocaleString('ko-KR', { minimumFractionDigits: 2 })
                            }}</ion-label>
                        </ion-item>
                        <ion-item>
                            <ion-label>배정</ion-label>
                            <ion-input id="ton" type="text" style="text-align: right" placeholder="수량을 입력하세요"
                                @input="bindton" :value="quntinfo.ton"></ion-input>
                        </ion-item>
                        <ion-item>
                            <ion-label class="ion-margin-vertical">비고</ion-label>
                            <ion-textarea id="remark" class="ion-no-padding" style="text-align: right"></ion-textarea>
                        </ion-item>
                    </ion-list>
                </div>
                <ion-grid>
                    <ion-row class="ion-justify-content-center">
                        <ion-col size-md="2" size-xs="6">
                            <ion-button expand="block" @click="sendApply" color="medium">등록</ion-button>
                        </ion-col>
                        <ion-col size-md="2" size-xs="6">
                            <ion-button expand="block" color="danger" @click="onReConnect">취소</ion-button>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </template>
            <template v-else>
                <network-error v-on:@onReConnect="onReConnect" />
            </template>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonContent,
    IonPage,
    onIonViewWillLeave,
    IonDatetime,
    IonItem,
    IonIcon,
    IonList,
    IonLabel,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    IonInput,
    IonSelectOption,
    IonSelect,
    modalController,
    onIonViewWillEnter,
    popoverController,
    IonModal,
    IonToolbar,
    IonHeader,
    IonButtons,
    loadingController,
    IonTextarea
} from '@ionic/vue';
import { defineComponent, ref, reactive, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { toast } from '@/utils/toast';
import { saleinfos } from '@/api/services/assignnew';
import { users } from '@/api/services/user';
import DplaceListModal from '@/views/modal/AssginDplaceListModal.vue';
import { setDateFormat } from '@/utils/dateFormatter';
import { calendar } from 'ionicons/icons';
export default defineComponent({
    components: {
        IonContent,
        IonPage,
        IonDatetime,
        IonItem,
        IonIcon,
        IonList,
        IonLabel,
        IonButton,
        IonGrid,
        IonRow,
        IonCol,
        IonInput,
        IonSelectOption,
        IonSelect,
        IonModal,
        IonToolbar,
        IonHeader,
        IonButtons,
        IonTextarea
    },
    setup() {
        const store = useStore();
        const auth = computed(() => store.getters['auth/getData']);
        const { saveLog } = users();
        const {
            isConnected,
            saleinfolist,
            fetchDatas,
            fetchOrderDatas,
            orderlist,
            fetchDeliveryDatas,
            deliverylist,
            fetchItnmDatas,
            itnmlist,
            salelist,
            fetchQuntData,
            quntable,
            insertAssign,
            fetchSaleDatas,
            fetchDplaceDatas,
            dplacelist
        } = saleinfos();
        const frDate = ref(setDateFormat(new Date(), 'YYYY-MM'));

        onIonViewWillEnter(async () => {
            fetchOrderDatas();
            fetchDeliveryDatas();
            fetchItnmDatas();
            fetchSaleDatas();
            fetchDplaceDatas(auth.value.id);
            saveLog(auth.value.id, '출하신규배정인천')
        });

        const dplacenm = reactive({
            title: '',
        });

        const sicd = reactive({
            code: '4000',
        });

        const saleinfo = reactive({
            officename: '',
            salename: '',
            salesano: '',
            saleower: '',
            ordercode: '',
            delicode: '',
            itnmcode: '',
            transportcode: '',
            remark: ''
        });

        const quntinfo = reactive({
            qunt: '0',
            ton: '',
            ton1: '',
            itnmcalc: '',
        });


        const form = reactive({
            modalState: false,
            popoverState: false
        });
        async function onWillDismiss() {
            form.modalState = false;
        }

        async function onWillDismissPopover() {
            form.popoverState = false;
        }
        async function modalChange() {
            const loading = await loadingController.create({
                message: '로딩중...',
                duration: 300
            });

            loading.present();
            form.modalState = true;
        }
        async function popChange() {
            form.popoverState = true;
        }
        function comma(str) {
            str = String(str);
            return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        }
        function uncomma(str) {
            str = String(str);
            return str.replace(/[^\d]+/g, '');
        }
        function bindton(event) {
            quntinfo.ton1 = Number(uncomma(event.target.value))
            quntinfo.ton = event.target.value
            quntinfo.ton = comma(uncomma(event.target.value))
            quntinfo.calton = Number(uncomma(event.target.value))
            if (saleinfo.itnmcode == '200667') {
                quntinfo.ton1 = quntinfo.calton * 0.04
            }
        }

        function selectSicd(e) {
            sicd.code = e.target.value;
        }

        function changeDate(e) {
            frDate.value = e.target.value;
            if (dplacenm.title) {
                fetchQuntData(frDate.value.substring(0, 10).replaceAll('-', ''), saleinfo.delicode, dplacenm.title, saleinfo.itnmcode,'4000')
                quntinfo.qunt = quntable.value[0].qunt
            }
        }


        watch(() => saleinfo.delicode, async (newValue, oldValue) => {
            if (oldValue != '' && newValue != oldValue) {
                await fetchQuntData(frDate.value.substring(0, 10).replaceAll('-', ''), saleinfo.delicode, dplacenm.title, saleinfo.itnmcode,'4000')
                quntinfo.qunt = quntable.value[0].qunt
            }
        })

        function changeDeli(e) {
            saleinfo.delicode = e.target.value
        }

        watch(() => saleinfo.itnmcode, async (newValue, oldValue) => {
            if (oldValue != '' && newValue != oldValue) {
                await fetchQuntData(frDate.value.substring(0, 10).replaceAll('-', '')+'01', saleinfo.delicode, dplacenm.title, saleinfo.itnmcode,'4000')
                quntinfo.qunt = quntable.value[0].qunt
                
            }
        })

        async function changeItnm(e) {
            saleinfo.itnmcode = e.target.value
            // await fetchQuntData(frDate.value.substring(0, 10).replaceAll('-', '')+'01', saleinfo.delicode, dplacenm.title, saleinfo.itnmcode,'4000')
            // quntinfo.qunt = quntable.value[0].qunt
        }

        function changeOrder(e) {
            saleinfo.ordercode = e.target.value
        }

        async function onReConnect() {
            saleinfo.delicode = '';
            saleinfo.itnmcode = '';
            saleinfo.officename = '';
            saleinfo.ordercode = '';
            saleinfo.salename = '';
            saleinfo.saleower = '';
            saleinfo.salesano = '';
            saleinfo.transportcode = '';
            document.querySelector('.dplace').value = null;

        }

        function cgname(e) {
            if (e == null) {
                document.querySelector('.dplace').value = null;

            } else {
                document.querySelector('.dplace').value = e;
            }
        }

        async function sendDplNm(dplnm) {
            this.$refs.modal1.$el.dismiss(null, 'confirm');
            cgname(dplnm);
            dplacenm.title = dplnm.substring(0, 6);
            await fetchDatas(dplacenm.title);

            saleinfo.officename = saleinfolist.value[0].officeName
            saleinfo.salename = saleinfolist.value[0].saleName
            saleinfo.salesano = saleinfolist.value[0].saleSano.substring(0, 3) + '-' + saleinfolist.value[0].saleSano.substring(3, 5) + '-' + saleinfolist.value[0].saleSano.substring(5)
            saleinfo.saleower = saleinfolist.value[0].saleOwer
            saleinfo.ordercode = saleinfolist.value[0].orderCode
            saleinfo.delicode = saleinfolist.value[0].deliveryCode
            saleinfo.itnmcode = saleinfolist.value[0].itnmCode


            await fetchQuntData(frDate.value.substring(0, 10).replaceAll('-', ''), saleinfo.delicode, dplacenm.title, saleinfo.itnmcode,'4000')
            quntinfo.qunt = quntable.value[0].qunt
        }

        async function cancel1() {
            this.$refs.modal1.$el.dismiss(null, 'cancel');
        }

        async function clear1() {
            this.$refs.modal1.$el.dismiss(null, 'cancel');
            cgname('');
            onReConnect();
        }

        async function searchGroup1(event) {
            //console.log(this.salelist[0].saleName);

            const filter = event.target.value;

            if (filter.length < 2) {
                return;
            }

            const dplaceNames = Array.from(document.querySelectorAll('.dplacename'));
            const groupLists = Array.from(document.querySelectorAll('.group-list'));

            this.dplacelist.forEach((item, index) => {
                const shouldShow = item.dplaceName.includes(filter);
                dplaceNames[index].style.display = shouldShow ? "flex" : "none";
                groupLists[index].style.display = shouldShow ? "flex" : "none";
            });


        }

        async function openModal() {
            form.modalState = true
            const modal = await modalController.create({
                component: DplaceListModal,
            });
            modal.present();

            const { data, role } = await modal.onWillDismiss();

            if (role === 'confirm') {

                cgname(data);
                dplacenm.title = data.substring(0, 6);

                await fetchDatas(dplacenm.title);

                saleinfo.officename = saleinfolist.value[0].officeName
                saleinfo.salename = saleinfolist.value[0].saleName
                saleinfo.salesano = saleinfolist.value[0].saleSano.substring(0, 3) + '-' + saleinfolist.value[0].saleSano.substring(3, 5) + '-' + saleinfolist.value[0].saleSano.substring(5)
                saleinfo.saleower = saleinfolist.value[0].saleOwer
                saleinfo.ordercode = saleinfolist.value[0].orderCode
                saleinfo.delicode = saleinfolist.value[0].deliveryCode
                saleinfo.itnmcode = saleinfolist.value[0].itnmCode


                await fetchQuntData(frDate.value.substring(0, 10).replaceAll('-', ''), saleinfo.delicode, dplacenm.title, saleinfo.itnmcode,'4000')
                quntinfo.qunt = quntable.value[0].qunt
            } else if (role === 'clear') {
                cgname(data);
                dplacenm.title = data;
            }
            form.modalState = false
        }

        function saname(e) {
            if (e == null) {
                document.querySelector('.sale').value = null;
            } else {
                document.querySelector('.sale').value = e;
            }
        }

        async function sendSaleNm(salenm) {
            this.$refs.modal.$el.dismiss(null, 'confirm');
            saname(salenm);
            saleinfo.transportcode = salenm.substring(0, 6);
        }

        async function cancel() {
            this.$refs.modal.$el.dismiss(null, 'cancel');
        }

        async function clear() {
            this.$refs.modal.$el.dismiss(null, 'cancel');
            saname('');
        }

        async function searchGroup(event) {


            const filter = event.target.value;

            if (filter.length < 2) {
                return;
            }

            const saleNames = Array.from(document.querySelectorAll('.salename'));
            const groupLists = Array.from(document.querySelectorAll('.group-list'));

            this.salelist.forEach((item, index) => {
                const shouldShow = item.saleName.includes(filter);
                saleNames[index].style.display = shouldShow ? "flex" : "none";
                groupLists[index].style.display = shouldShow ? "flex" : "none";
            });


        }


        function errChk() {
            if (saleinfo.ordercode == '') {
                toast("주문유형을 선택해주세요.");
                return -1;
            }
            if (saleinfo.delicode == '') {
                toast("인도조건을 선택해주세요.");
                return -1;
            }
            if (saleinfo.itnmcode == '') {
                toast("품목을 선택해주세요.");
                return -1;
            }
            if (sicd.code == '5000') {
                if (saleinfo.transportcode == '') {
                    return 1;
                }
            } else {
                
                if (saleinfo.transportcode == '') {
                    toast("운송사를 선택해주세요.");
                    return -1;
                }
            }
            if (document.querySelector('#ton').value == '') {
                toast("수량을 입력해주세요.");
                return -1;
            }
            if (Number(quntinfo.qunt) - Number(uncomma(quntinfo.ton)) < 0) {
                toast("배정가능수량을 초과하였습니다.")
                return -1;
            }
            return 1;
        }

        async function sendApply() {

            const a = errChk();
            if (a == -1) {
                console.log("등록못함")
            } else {
                insertAssign(
                    frDate.value.substring(0, 10).replaceAll('-', ''),
                    dplacenm.title,
                    sicd.code,
                    saleinfo.ordercode,
                    saleinfo.itnmcode,
                    saleinfo.delicode,
                    document.querySelector('#ton').value.replaceAll(',', ''),
                    auth.value.id,
                    saleinfo.transportcode,
                    document.querySelector('#remark').value
                )
            }

        }

        onIonViewWillLeave(async () => {
            if (form.modalState == true) {
                await modalController.dismiss()
            } else if (form.popoverState == true) {
                await popoverController.dismiss()
            }
        });

        return {
            frDate,
            calendar,
            sicd,
            selectSicd,
            isConnected,
            saleinfolist,
            openModal,
            cgname,
            dplacenm,
            saleinfo,
            orderlist,
            deliverylist,
            itnmlist,
            salelist,
            saname,
            quntinfo,
            changeDate,
            changeDeli,
            changeItnm,
            changeOrder,
            sendApply,
            bindton,
            onReConnect,
            form,
            modalChange,
            onWillDismiss,
            onWillDismissPopover,
            popChange,
            searchGroup,
            sendSaleNm,
            cancel,
            clear,
            dplacelist,
            sendDplNm,
            cancel1,
            clear1,
            searchGroup1
        };
    }
});
</script>

<style scoped>
#container {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
}

ion-item {
    --padding-start: 0px;
}

ion-item ion-label {
    padding-left: 10px;
    min-width: 4em;
    color: blue;
}

ion-item ion-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    overflow: hidden;
}

ion-item ion-input {
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    overflow: hidden;
}

ion-button {
    width: 100%;
    margin: 1.5em 0;
    height: 3em;
}

ion-skeleton-text {
    width: 30%;
}

ion-select {
    position: relative;
    max-width: 100% !important;
}

ion-select-option {
    max-width: 100% !important;
}

ion-item span {
    padding-left: 10px;
}

.info {
    text-align: right;
}

ion-grid {
    padding-bottom: 0;
    padding-top: 0;
}

ion-list {
    padding: 0;
}

.group-list {
    margin: auto;

    display: flex;
}

.group-list ion-label {
    display: flex;
    justify-content: left;
    align-items: left;
    height: 32px;
    list-style-type: none;
}
</style>